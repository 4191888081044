// idk how to do this in a cleaner way :(
const loadTranslationForPage = (lang, currPage, setTranslation) => {
	import(`../../pages/${currPage}/${lang}.json`)
		.then((translation) => {
			setTranslation(translation);
		})
		.catch(error => error);
}

const loadTranslationForUtility = (lang, utility, setTranslation) => {
	import(`../${utility}/${lang}.json`)
	.then((translation) => {
		setTranslation(translation);
	})
	.catch(error => error);
}


export { loadTranslationForPage, loadTranslationForUtility};