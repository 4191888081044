import { combineReducers } from 'redux';
import openSidebarReducer from './sidebarReducer';
import colormodeReducer from './colormodeReducer';
import languageReducer from './languageReducer';
import videoIdReducer from './videoIdReducer';
import uploadstatusReducer from './uploadstatusReducer';
import fileUrlsReducer from './fileUrlsReducer';
import settingsReducer from './settingsReducer';
import loadingstatusReducer from './loadingstatusReducer';
import videolengthReducer from './videolengthReducer'

const rootReducer = combineReducers({
	sidebar: openSidebarReducer,
	colormode: colormodeReducer,
	language: languageReducer,
	videoId: videoIdReducer,
	uploadStatus: uploadstatusReducer,
	fileUrls: fileUrlsReducer,
	settings: settingsReducer,
	loadingstatus: loadingstatusReducer,
	videoLength: videolengthReducer
});

export default rootReducer;