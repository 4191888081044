import React from 'react';
import './Spinner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'
library.add(faSpinner);

export default function Spinner({positioning = 'absolute', size='6'}) {
    return (
        <div className={`spinner-wrapper-${positioning}`}>
            <FontAwesomeIcon icon="spinner" size={`${size}x`} spin />
        </div>
    )
}