import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Spinner from './utilities/Spinner/Spinner'
import Homepage from './pages/Homepage/Homepage';
import './App.css';

const NotFound = lazy(() => import('./pages/NotFound/NotFound'))
const Vision = lazy(() => import('./pages/Vision/Vision'))
const HiveInformation = lazy(() => import('./pages/HiveInformation/HiveInformation'))
const HiveDetail = lazy(() => import('./pages/HiveDetail/HiveDetail'))
const Videoupload = lazy(() => import('./pages/Video/Videoupload'))
// const Paper = lazy(() => import('./pages/Paper/Paper'))
const Considerations = lazy(() => import('./pages/Paper/Considerations'))
const About = lazy(() => import('./pages/About/About'))
const Navbar = lazy(() => import('./utilities/Navbar/Navbar'))
const Sidebar = lazy(() => import('./utilities/Sidebar/Sidebar'))
const Footer = lazy(() => import('./utilities/Footer/Footer'))


function App() {
  return (
    <Router>
      <Suspense fallback={(
        <div className='app'>
          <main>
            <Spinner />
          </main>
        </div>
      )}>
        <div className="app">
          <Route path="/" component={Navbar} />
          <Route path="/" component={Sidebar} />
          <main>
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/project" component={Vision} />
              <Route exact path="/hiveoverview" component={HiveInformation} />
              <Route exact path="/hiveoverview/:hiveId" component={HiveDetail} />
              <Route exact path="/video" component={Videoupload} />
              <Route exact path="/about" component={About} />
              {/* <Route exact path="/paper" component={Paper} /> */}
              <Route exact path="/considerations" component={Considerations} />
              <Route component={NotFound} />
            </Switch>
          </main>
          <Route path="/" component={Footer} />
        </div>
      </Suspense>
    </Router>
  );
}

export default App;