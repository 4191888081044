import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import M from 'materialize-css';
import { loadTranslationForPage } from '../../utilities/functions/loadTranslation'
import './Homepage.scss';
import { Link } from 'react-router-dom';

export default function Homepage(props) {
	const language = useSelector(state => state.language.newLanguage);
	const [translation, setTranslation] = useState({});

	useEffect(() => {
		for (let i = 0; i < 15; i++) {
			setTimeout(() => {
				// setup paralax effect
				const parallaxElements = document.querySelectorAll('.parallax');
				console.log(parallaxElements)
				M.Parallax.init(parallaxElements, {
					height: "800px"
				});
			}, 200 * i)
		}
	}, [])

	useEffect(() => {
		loadTranslationForPage(language, 'Homepage', setTranslation);
	}, [language])

	return (
		<div className="homepage page">
			<ParallaxContainer >
				<span className="heading">Bee Supervisor 3000</span>
			</ParallaxContainer>
			<div className="section">
				<div className="row container">
					<h2 className="header text-center">{translation?.heading1}</h2>
				</div>
			</div>
			<ParallaxContainer />
			<div className="section">
				<div className="row container">
					<h2 className="header">{translation?.heading2}</h2>
				</div>
			</div>
			<ParallaxContainer />
			<div className="section">
				<div className="row container">
					<h2 className="header text-right">{translation?.heading3}</h2>
				</div>
			</div>
			<ParallaxContainer />
			<div className="section">
				<div className="row container">
					<h2 className="header">{translation?.heading4}</h2>
					<p className='slogan'>
						{translation?.pitch1}
						<Link to={'/project'}>{translation?.linkName}</Link>
						{translation?.pitch2}
					</p>
				</div>
			</div>
			<div className='video-container'>
				<video id="background-video" loop muted autoPlay>
					<source src="/pictures/whole.webm" type="video/webm" />
				</video>
			</div>

		</div >
	)
}

const ParallaxContainer = props => {
	return (<div className="parallax-container short-container">
		<div className="parallax"><img src="/pictures/bees-background.jpg" alt="nice background" /></div>
		{props.children}
	</div>)
}
